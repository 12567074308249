import Select, {StylesConfig} from 'react-select'
import {MultiSelectOption} from '../models/MultiSelectOption'

interface ListProps {
  setSelectedOption: React.Dispatch<React.SetStateAction<any>>
  options: MultiSelectOption[]
  defaultValues?: MultiSelectOption[]
  isMulti?: boolean
}

const MultiSelect = ({setSelectedOption, options, defaultValues, isMulti = true}: ListProps) => {
  if (typeof defaultValues === 'undefined') {
    return (
      <Select
        onChange={setSelectedOption}
        options={options}
        isMulti={isMulti}
        isSearchable={true}
      />
    )
  }

  return (
    <Select
      onChange={setSelectedOption}
      options={options}
      isMulti={isMulti}
      isSearchable={true}
      value={typeof defaultValues !== 'undefined' && defaultValues !== null ? defaultValues : []}
    />
  )
}

export {MultiSelect}
