import {useEffect, useState} from 'react'

import {KTSVG} from '../../../../_metronic/helpers'
import {useIntl} from 'react-intl'
import {useAuth} from '../../auth'
import {MultiSelect} from '../../../common/components/MultiSelect'
import {MultiSelectOption} from '../../../common/models/MultiSelectOption'

const SelectClientDrawer = () => {
  const intl = useIntl()
  const {auth, changeClientKey} = useAuth()
  const [allOptions, setAllOptions] = useState<MultiSelectOption[]>([])
  const [selectedKey, setSelectedKey] = useState<MultiSelectOption | null>(null)

  useEffect(() => {
    if (selectedKey !== null) {
      changeClientKey(selectedKey.value, selectedKey.label)
    } else {
      const client = auth?.clientAccess.find((item) => item.clientKey === auth.clientKey)
      changeClientKey(client?.clientKey!, client?.name!)
    }
  }, [selectedKey])

  useEffect(() => {
    if (
      typeof auth !== 'undefined' &&
      auth !== null &&
      auth.clientAccess !== null &&
      auth.clientAccess.length > 0
    ) {
      var items = auth.clientAccess.map((x) => {
        return {label: x.name, value: x.clientKey} as MultiSelectOption
      })
      setAllOptions(items)
    } else {
      setAllOptions([])
    }
  }, [auth])

  return (
    <div
      id='kt_access'
      className='bg-white'
      data-kt-drawer='true'
      data-kt-drawer-name='access'
      data-kt-drawer-activate='true'
      data-kt-drawer-overlay='true'
      data-kt-drawer-width="{default:'300px', 'lg': '600px'}"
      data-kt-drawer-direction='end'
      data-kt-drawer-toggle='#kt_access_toggle'
      data-kt-drawer-close='#kt_access_close'
    >
      <div className='card shadow-none w-100 rounded-0'>
        <div className='card-header' id='kt_access_header'>
          <h3 className='card-title fw-bolder text-dark'>Select Client</h3>

          <div className='card-toolbar'>
            <button
              type='button'
              className='btn btn-sm btn-icon btn-active-light-primary me-n5'
              id='kt_access_close'
            >
              <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
            </button>
          </div>
        </div>
        <div className='card-body position-relative ' id='kt_access_body'>
          <label className='form-label'>{intl.formatMessage({id: 'FORM.CLIENT'})}</label>
          <MultiSelect setSelectedOption={setSelectedKey} options={allOptions} isMulti={false} />
        </div>
      </div>
    </div>
  )
}

export {SelectClientDrawer}
